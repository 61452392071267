import { addStats } from 'pixi-stats';
import { Application, Loader, Rectangle, Renderer, Ticker, UPDATE_PRIORITY, utils } from 'pixi.js';
import { Eventify } from '../../../Shared/Eventify';
import { App } from '../App';
import { settings } from '../utils/Settings';
import AirplaneScene from './AirplaneScene';
import Background from './Background';
import { ResourceController } from './ResourceLoader';
import Foreground from './foregrounds/Foreground';
import ResizeObserver from 'resize-observer-polyfill';

enum Events {
    resize = 'resize',
    rafResize = 'rafResize',
    destroy = 'destroy',
    tick = 'tick'
}

export default class Visualisation extends Eventify {
    static readonly Events = Events;
    app: App;
    canvas: HTMLCanvasElement;
    render: Application;
    background!: Background;
    airplaneScene!: AirplaneScene;
    size: Rectangle;
    scale = 1;
    width = 0;
    height = 0;

    foreground!: Foreground;
    constructor(
        canvas: HTMLCanvasElement,
        app: App,
        private t: (key: string) => string,
        onReady: () => void
    ) {
        super();
        this.app = app;
        this.canvas = canvas;
        this.canvas.style.display = 'inline-block';

        this.resizeObserver();
        utils.skipHello();
        this.render = new Application({
            view: canvas,
            //resizeTo: <HTMLElement>canvas.parentElement,
            useContextAlpha: true,
            antialias: true,
            resolution: devicePixelRatio || 1,
            autoDensity: true
            // useContextAlpha: false,
            // antialias: false
        });

        this.render.view.addEventListener('webglcontextlost', (event: any) => {
            event.preventDefault();
            if (this.render.renderer instanceof Renderer) {
                console.log('Restoring context');
                this.render.renderer.gl.getExtension('WEBGL_lose_context')?.restoreContext();
            }
        });

        this.size = this.render.screen;
        this.width = this.size.width;
        this.height = this.size.height;

        window.addEventListener('resize', () => this.resizeCanvas());
        //this.on(Events.rafResize, this.resizeCanvas)();

        const tickEmit = () => this.emit(Events.tick);
        Ticker.shared.add(tickEmit);

        if (false) {
            // Add stats
            const stats = addStats(document, this.render);
            Ticker.shared.add(stats.update, stats, UPDATE_PRIORITY.UTILITY);
        }

        this.listenSelf(Events.destroy, () => Ticker.shared.remove(tickEmit));

        this.loadAssets()
            .then(this.initGame)
            .then(() => {
                requestAnimationFrame(onReady);
            });
    }

    resizeCanvas = () => {
        this.render.renderer.resolution = devicePixelRatio || 1;

        if (!this.canvas.parentElement) return;
        this.render.renderer.resize(this.canvas.parentElement.offsetWidth, this.canvas.parentElement.offsetHeight);
        this.render.renderer.render(this.render.stage);

        this.width = this.canvas.parentElement.offsetWidth;
        this.height = this.canvas.parentElement.offsetHeight;

        this.emit(Visualisation.Events.resize);
        // This is hotfix for odd behaviour on resizing
        this.emit(Visualisation.Events.resize);
    };

    resizeObserver() {
        let frameid: number;

        const observer = typeof window.ResizeObserver !== 'undefined' ? window.ResizeObserver : ResizeObserver;

        const resizeObserver = new observer(() => {
            frameid = requestAnimationFrame(() => {
                cancelAnimationFrame(frameid);
                this.emit(Events.rafResize);
            });
        });

        resizeObserver.observe(<HTMLElement>this.canvas.parentElement);

        this.listenSelf(Events.destroy, () => {
            resizeObserver.disconnect();
        });
    }

    destroy() {
        this.emit(Events.destroy);
        this.unlisten();
    }

    initGame = () => {
        this.background = new Background(this);
        this.render.stage.addChild(this.background.container);

        this.airplaneScene = new AirplaneScene(this);
        this.render.stage.addChild(this.airplaneScene.container);

        this.listenTo(settings, 'animationEnabled', (enabled = settings.proxy.animationEnabled) => {
            if (enabled) {
                this.render.stage.addChild(this.airplaneScene.container);
            } else {
                this.render.stage.removeChild(this.airplaneScene.container);
            }
        })();

        this.foreground = new Foreground(this, this.t);
        this.render.stage.addChild(this.foreground.container);
        this.resizeCanvas();
    };

    loadAssets() {
        return new Promise((resolve) => {
            ResourceController.addResources('main');
            ResourceController.loadResources('main');
            const fontsLoaderPromise = ResourceController.loadFonts('main');

            Promise.all([new Promise((r) => Loader.shared.load(r)), fontsLoaderPromise]).then(resolve);
        });
    }
}
