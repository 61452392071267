import {
    IAtlasResources,
    IAudioResources,
    IBitmapFontResources,
    IFontResources,
    IImageResources,
    IMultiJsonResources,
    ISpineResources
} from './Models/AssetsModel';

export const ANIMATIONS: ISpineResources = {
    defaultPath: 'spine/',

    main: []
};

/** Should be in assets/spine/ */
export const MULTI_JSON_ASSETS: IMultiJsonResources = {
    defaultPath: 'spine/',
    main: []
};

/**
 * Should be in assets/audio/
 *
 * .MP3 or .WAV formats
 */
export const AUDIO: IAudioResources = {
    defaultPath: 'audio/',
    main: [
        {
            type: 'MUSIC',
            autoplay: false,
            loop: true,
            key: 'background',
            path: 'background.mp3'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'airplaneAudio',
            path: 'airplane.mp3'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'click',
            path: 'click.mp3'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'info',
            path: 'info.mp3'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'cashier',
            path: 'cashier.mp3'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'bet_click',
            path: 'bet_click.ogg'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'cash_out_click',
            path: 'cash_out_click.ogg'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: true,
            key: 'airplane_loop',
            path: 'airplane_loop.ogg'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'airplane_start',
            path: 'airplane_start.ogg'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'airplane_end',
            path: 'airplane_end.ogg'
        }
    ]
};

export const IMAGES: IImageResources = {
    defaultPath: 'sprites/',

    main: [
        {
            key: 'poweredby',
            path: 'poweredby.png'
        },
        {
            key: '0',
            path: 'avatars/avatars-0.png'
        },
        {
            key: '1',
            path: 'avatars/avatars-1.png'
        },
        {
            key: '2',
            path: 'avatars/avatars-2.png'
        },
        {
            key: '3',
            path: 'avatars/avatars-3.png'
        },
        {
            key: '4',
            path: 'avatars/avatars-4.png'
        },
        {
            key: '5',
            path: 'avatars/avatars-5.png'
        },
        {
            key: '6',
            path: 'avatars/avatars-6.png'
        },
        {
            key: '7',
            path: 'avatars/avatars-7.png'
        },
        {
            key: '8',
            path: 'avatars/avatars-8.png'
        },
        {
            key: '9',
            path: 'avatars/avatars-9.png'
        },
        {
            key: '10',
            path: 'avatars/avatars-10.png'
        },
        {
            key: '11',
            path: 'avatars/avatars-11.png'
        },
        {
            key: '12',
            path: 'avatars/avatars-12.png'
        },
        {
            key: '13',
            path: 'avatars/avatars-13.png'
        },
        {
            key: '14',
            path: 'avatars/avatars-14.png'
        },
        {
            key: '15',
            path: 'avatars/avatars-15.png'
        },
        {
            key: '16',
            path: 'avatars/avatars-16.png'
        },
        {
            key: '17',
            path: 'avatars/avatars-17.png'
        },
        {
            key: '18',
            path: 'avatars/avatars-18.png'
        },
        {
            key: '19',
            path: 'avatars/avatars-19.png'
        },
        {
            key: '20',
            path: 'avatars/avatars-20.png'
        },
        {
            key: '21',
            path: 'avatars/avatars-21.png'
        },
        {
            key: '22',
            path: 'avatars/avatars-22.png'
        },
        {
            key: '23',
            path: 'avatars/avatars-23.png'
        },
        {
            key: '24',
            path: 'avatars/avatars-24.png'
        },
        {
            key: '25',
            path: 'avatars/avatars-25.png'
        },
        {
            key: '26',
            path: 'avatars/avatars-26.png'
        },
        {
            key: '27',
            path: 'avatars/avatars-27.png'
        },
        {
            key: 'AmigatorPropeller',
            path: 'amigator_propeller.png'
        },
    ]
};

export const SPRITESHEETS: IImageResources = {
    defaultPath: 'spritesheets/',

    main: [
        {
            key: 'airplaneAnimation',
            path: 'plane.json'
        },
        {
            key: 'airplaneMain',
            path: 'airplane_main.json'
        },
        {
            key: 'airplanePropeller',
            path: 'airplane_propeller.json'
        },
    ]
};

/** Should be in assets/atlases/ */
export const ATLASES: IAtlasResources = {
    defaultPath: 'atlases/',

    main: []
};

/** Should be in assets/bitmapfonts/ */
export const BITMAP_FONTS: IBitmapFontResources = {
    defaultPath: 'bitmapfonts/',

    main: []
};

/** Should be in assets/fonts/ */
export const FONTS: IFontResources = {
    defaultPath: 'fonts/',

    main: [
        {
            key: 'Roboto-Bold',
            path: 'Roboto-Bold.ttf'
        },
        {
            key: 'Roboto-Regular',
            path: 'Roboto-Regular.ttf'
        }
    ]
};
