import { Howl } from 'howler';
import { deferrify } from '../../Shared/Eventify';
import { AddPropertiesToWindow } from './Components/utils';
import { ResourceType } from './Visualisation/Models/AssetsModel';
import { AUDIO } from './Visualisation/assets';
import { config } from './config';
import { App } from './App';
import { RoundState } from '../../Shared/Types';

export default class AudioManager {
    private musicVolume = 1;
    private soundVolume = 1;
    private app : App;

    get targetMusicVolume() {
        return this.pageVisible ? this.musicVolume : 0;
    }
    get targetSoundVolume() {
        return this.pageVisible ? this.soundVolume : 0;
    }
    MUSIC: Howl[] = [];
    SOUND_FX: Howl[] = [];
    audioList: { [key: string]: Howl } = {};
    private pageVisible = false;
    private audioLoaded = false; // Flag to track if audio has been loaded

    constructor(app: App) {
        AddPropertiesToWindow({ Howl: Howl });
        this.pageVisible = !document.hidden;

        addEventListener('focus', () => {
            this.visibilityChange(true);
        });

        addEventListener('blur', () => {
            this.visibilityChange(false);
        });

        document.addEventListener('visibilitychange', () => {
            this.visibilityChange(!document.hidden);
        });

        // Listen for a user interaction to load audio
        document.body.addEventListener('pointerup', this.initializeAudio, { once: true });
        this.app = app;
    }

    visibilityChange(isVisible: boolean) {
        this.pageVisible = isVisible;
        this.setMusicVolume(this.musicVolume);
        this.setSoundVolume(this.soundVolume);
    }

    private initializeAudio = () => {
        if (!this.audioLoaded) {
            this.loadAudio('main');
            this.audioLoaded = true;
        }
        this.audioList.background.play();
        if (this.app.state.roundState == RoundState.ROUND_STARTED) {
            this.audioList.airplane_loop.play(this.targetMusicVolume);
            this.audioList.airplane_loop.volume(this.targetSoundVolume);
        }
    };

    loadAudio = (type: ResourceType) => {
        const promisesArray = AUDIO[type]!.map((audio) => {
            const deferred = deferrify<Howl>();
            const howl = new Howl({
                src: [`${config.assetsLocation}${AUDIO.defaultPath}${audio.path}`],
                loop: !!audio.loop,
                autoplay: false, // Disable autoplay to avoid issues
                onload: () => {
                    howl.volume(audio.type === 'MUSIC' ? this.targetMusicVolume : this.targetSoundVolume);
                    deferred.resolve(howl);
                }
            });

            if (audio.type === 'MUSIC') {
                this.MUSIC.push(howl);
            }

            if (audio.type === 'FX') {
                this.SOUND_FX.push(howl);
            }

            this.audioList[audio.key] = howl;
            return deferred.promise;
        });

        return Promise.all(promisesArray);
    };

    setMusicVolume = (volume: number) => {
        this.musicVolume = volume;
        for (const o of this.MUSIC) {
            o.volume(this.targetMusicVolume);
        }
    };

    setSoundVolume = (volume: number) => {
        this.soundVolume = volume;
        for (const o of this.SOUND_FX) {
            o.volume(this.targetSoundVolume);
        }
    };
}

// window.addEventListener('focus', () => {
//     if (Global_Vars.are_sound_fx_on && Global_Vars.is_music_on) {
//         Howler.mute(false);
//     }
// });

// document.addEventListener('visibilitychange', () => {
//     if (document.hidden) {
//         Howler.mute(true);
//     } else {
//         if (Global_Vars.are_sound_fx_on && Global_Vars.is_music_on) {
//             Howler.mute(false);
//         }
//     }
// });
