import { Dialog } from '@headlessui/react';
import { t } from 'i18next';
import Cookies from 'js-cookie';
import { useContext } from 'react';
import { getColorForMultiplier } from '../../config';
import { MicroColor } from '../../utils/Microcolor';
import { appContext } from '../contexts';
import Button from '../other/Button';
import { DialogContext } from '../other/Dialog';

export default function ModalAvatarChoose() {
    const app = useContext(appContext);
    const { close } = useContext(DialogContext);

    const changeAvatar = (avatarId: number) => {
        app.avatarId = avatarId;

        Cookies.set('avatarId', avatarId.toString());

        close && close();
    };

    return (
        <>
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 modal-header">
                Choose game avatar
            </Dialog.Title>

            <div className="flex flex-wrap justify-center gap-2 my-4 p-2.5">
                {Array.from({ length: 28 }).map((_, i) => (
                    <button
                        key={i}
                        className="flex flex-row justify-center items-center w-24 aspect-square rounded-full overflow-hidden"
                        style={{ background: MicroColor.temp.fromINT(getColorForMultiplier(2 ** i)).getHEX() }}
                        onClick={() => changeAvatar(i)}
                    >
                        <div className={`flex w-full avatar${i < 10 ? '0' : ''}${i}`}></div>
                    </button>
                ))}
            </div>

            <div className="bottom-bar">
                <Button onClick={close}>{t('cancel')}</Button>
            </div>
        </>
    );
}
