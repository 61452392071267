import Dinero from 'dinero.js';
import gsap from 'gsap';
import { BitmapText, Container, Graphics, Sprite } from 'pixi.js';
import { IBetBasicEntry } from '../../../Shared/TypesBetboard';
import { config } from '../config';
import { money } from '../utils/utils';
import { ResourceController } from './ResourceLoader';

export class Cashout extends Container {
    cashoutEntry: IBetBasicEntry | null = null;
    options = {
        isMobile: false
    };
    targetSize: number;
    hovered = false;
    minimized: boolean;
    hidden: boolean;
    elapsed: number;
    multiplier = 1;
    cashoutValue = 0;

    // flipped: boolean;
    targetPosition = null;
    formatCurrency = money;
    textMultiplier: BitmapText;
    textCashOut: BitmapText;
    icon: Graphics;

    constructor(
        timeElapsed: number,
        isMinimized = false,
        // isFlipped = true,
        // fnFormatCurrency = (v: number) => String(v),
        options = {
            isMobile: false
        }
    ) {
        super();
        Object.assign(this.options, options);

        this.targetSize = this.getTargetSize();
        this.minimized = isMinimized;
        this.hidden = options.isMobile;
        this.elapsed = timeElapsed;
        // this.flipped = isFlipped;
        // this.formatCurrency = fnFormatCurrency;

        const icon = (this.icon = new Graphics());
        icon.beginFill(0xffffff, 1);
        icon.drawCircle(0, 0, this.targetSize * 2);
        icon.endFill();

        this.textMultiplier = new BitmapText('1.00x', {
            fontName: 'RobotoCashOutMultiplier',
            fontSize: 20,
            tint: 0xffffff
        });
        this.textMultiplier.anchor.set(0.5, 1);

        this.textCashOut = new BitmapText('USD 14.00', {
            fontName: 'RobotoCashOutAmount',
            fontSize: 20
        });
        this.textCashOut.anchor.set(0.5, 1);

        if (this.minimized) {
            this.minimize();
        } else {
            this.unminimize();
        }

        this.setName('0.00x');
        this.setCashoutValue(this.cashoutValue);
        this.setMultiplier(this.multiplier);
        this.addChild(icon, this.textMultiplier, this.textCashOut);

        this.onResize();

        this.cacheAsBitmap = true;
    }

    updateImage() {
        const mask = new Graphics();
        mask.beginFill(0xffffff);
        mask.drawCircle(0, 0, this.targetSize * 2); // Same size as the icon
        mask.endFill();

        this.icon.removeChildren();
        const sprite = Sprite.from(ResourceController.getTexture(this.cashoutEntry?.img || '0'));
        sprite.position.set(0, 0);
        sprite.anchor.set(0.5);
        const aspect = sprite.width / sprite.height;
        sprite.width = this.targetSize * 6;
        sprite.height = sprite.width / aspect;
        sprite.mask = mask;

        this.icon.addChild(sprite);
        this.icon.addChild(mask);
    }

    updateCashoutAmount() {
        const dinero = Dinero({ amount: Math.trunc(this.cashoutValue * 100), precision: 2 });

        this.textCashOut.text = this.formatCurrency(
            dinero.convertPrecision(config.bet.precision).getAmount(),
            config.bet
        );
    }

    updateCurrencyFormat(t: Cashout['formatCurrency']) {
        this.formatCurrency = t;
        this.updateCashoutAmount();
    }

    getTargetSize() {
        const t = this.options;

        if (t.isMobile) {
            return 25;
        } else {
            return 50;
        }
    }

    setTextPositions() {
        //console.log('targetSize', this.targetSize, this.textMultiplier.height, this.elapsed);
        this.textMultiplier.y = 10 - this.targetSize;
        this.textCashOut.y = this.textMultiplier.y - this.textMultiplier.height;
    }

    onResize() {
        this.targetSize = this.getTargetSize();
        this.icon.width = this.icon.height = this.targetSize;

        if (!this.hidden) {
            const t = 14;

            this.textMultiplier.fontSize = t;
            this.textCashOut.fontSize = t;

            this.setTextPositions();
        }
    }

    onDestroy() {
        gsap.killTweensOf(this.icon);
    }

    setName(t: string) {
        this.textMultiplier.text = t;
    }

    setObject() {}

    setMultiplier(multiplier: number) {
        if (multiplier === this.multiplier) return;
        this.multiplier = multiplier;
        this.textMultiplier.text = `${multiplier.toFixed(2)}x`;
    }

    setCashoutValue(amount: number) {
        if (amount === this.cashoutValue) return;

        this.cacheAsBitmap = false;

        this.cashoutValue = amount;
        this.updateCashoutAmount();

        this.cacheAsBitmap = true;
    }

    setVisibility(visible: boolean) {
        if (visible && this.visible == false) {
            this.fadeIn();
            this.visible = visible;
        }
    }

    fadeIn() {
        this.alpha = 0;
        gsap.to(this, 0.5, {
            ease: 'easeOutBack',
            alpha: 1,
            width: this.targetSize,
            height: this.targetSize,
            onComplete: () => {
                this.onDestroy();
            }
        });
    }

    minimize() {
        if (!this.minimized && !this.hidden) {
            this.minimized = true;
            this.textMultiplier.visible = false;
            this.textCashOut.visible = false;
        }
    }

    unminimize() {
        if (!this.hidden) {
            this.minimized = false;
            this.textMultiplier.visible = true;
            this.textCashOut.visible = true;
        }
    }
}
