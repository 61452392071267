import Decimal from 'decimal.js-light';
import { t } from 'i18next';
import Cookies from 'js-cookie';
import moment from 'moment';
import { Ticker } from 'pixi.js';
import { createElement } from 'react';
import ReactDOM from 'react-dom/client';
import packageInfo from '../../package.json';
import { DebuggerMixin } from '../../Shared/Debugger';
import { Eventify, EventObject } from '../../Shared/Eventify';
import { IServerState2, RoundState2Message } from '../../Shared/EventRoundStates';
import {
    ClientPlayMode,
    IServerConfigMessage,
    IServerPlayerState,
    ServerMessageType,
    THistoryRoundMultipliersEntry
} from '../../Shared/MessageTypes';
import MultiplierCalculator from '../../Shared/MultiplierCalculator';
import { createDemoSession, fetchBetHistory, fetchPlayerData } from '../../Shared/services/queries';
import { RoundState, RoundStateEvent, SrvState } from '../../Shared/Types';
import { IBetBasicEntry } from '../../Shared/TypesBetboard';
import { adjustAmountByCurrencyConfig, generateSeed } from '../../Shared/utils';
import AudioManager from './AudioManager';
import Bet from './Bet';
import { mapServerBetToIBetInfo, MyBetType } from './Components/Betlog/MyBets';
import { AddPropertiesToWindow } from './Components/utils';
import { config } from './config';
import Network, { wsErrorStatus } from './Network';
import { BetBoardEntry } from './Objects';
import { AppEvents, ClientBuildEnvironment } from './Types';
import { settings } from './utils/Settings';
import SuperMap from './utils/SuperMap';
import { Layout } from './Views/Layout';

declare const __ENVIRONMENT__: ClientBuildEnvironment;
//console.log('[[36mAMIGATOR[39m]:', `v${packageInfo.version}`, 'Build:', __ENVIRONMENT__.MODE);

const TIME_DIFF_HISTOGRAM_ENABLED = false;

type PingObject = {
    startTimestamp: number;
    endTimestamp?: number;
    ping?: number;
    serverTimestamp?: number;
    timeDiff?: number;
};

export class App extends DebuggerMixin(Eventify) {
    config = config;
    private calculator = new MultiplierCalculator(0);
    network!: Network;
    audio: AudioManager = new AudioManager(this);

    private defaultState = {
        clientPlayMode: ClientPlayMode.normal,
        name: '',
        balance: 0,
        balanceUpdate: -1,
        win: 0,
        targetMultiplier: 0,
        lastMultiplierUpdate: 0,
        serverMultiplierElapsedTime: 0,
        serverState: SrvState.STOPPED,
        roundState: RoundState.ROUND_ENDED,
        betOpenTime: 0,
        betEndTime: 0,
        serverRoundStartTime: 0,
        serverRoundEndTime: 0,
        clientSeed: generateSeed(),
        multipliersHistory: [] as THistoryRoundMultipliersEntry[],
        token: '',
        roundStartTimeLocal: 0,
        serverElapsedTime: 0
    };
    state = EventObject(Object.assign({}, this.defaultState));
    realtimeState = EventObject({
        multiplier: this.realtimeMultiplier
    });
    bets: Set<Bet> = new Set();
    timeDiff = 0;
    ping = 0; // average ping in ms divided by 2 --> TODO should be renamed to latency later
    pingVariability = 0;
    remainingTimeDiff = 0;

    roundElapsedTimeUpdate = 0;

    lastTimeDiffOnMultiplier = 0;
    roundElapsedTime = 0;
    currentBetBoard: SuperMap<string, BetBoardEntry> = new SuperMap();
    myBets: SuperMap<string, MyBetType> = new SuperMap();
    pingArray: PingObject[] = [];
    timeDiffArray: number[] = [];
    messageHistogram: Array<[string, string]> = [];
    timeDiffHistogram: Array<[string, number]> = [];
    animationEnabled: boolean = true;
    gRPCFailed = false;

    rtMultiplierValue: number = 0;

    avatarId: number | string = Cookies.get('avatarId') || '1';

    get betOpenTime() {
        return this.normalizeTime(this.state.betOpenTime);
    }
    get betEndTime() {
        return this.normalizeTime(this.state.betEndTime);
    }
    get roundStartTime() {
        return this.normalizeTime(this.state.serverRoundStartTime);
    }
    get roundEndTime() {
        return this.normalizeTime(this.state.serverRoundEndTime);
    }

    constructor() {
        super();

        if (window) {
            // @ts-ignore
            window.showHistogram = () => {
                //console.log('Histogram:', this.messageHistogram);
            };

            // @ts-ignore
            window.showPing = () => {
                //console.log('Ping:', this.pingArray);
            };
        }

        const parseUrl = new URL(window.location.href);
        const token = parseUrl.searchParams.get('token');

        if (!token) {
            createDemoSession()
                .then((response) => {
                    const parseUrl = new URL(response);
                    const token = parseUrl.searchParams.get('token');

                    if (!token) {
                        throw new Error('Token not found');
                    }

                    fetchPlayerData(token)
                        .then((data) => {
                            const url = new URL(location.href);
                            url.searchParams.set('token', token);
                            history.replaceState(null, '', url);

                            // TODO deduplicate with fetchPlayerData below
                            const parsedData = JSON.parse(data);

                            this.state.balance = parsedData.balance;
                            this.state.name = parsedData.userName;

                            if (parsedData.gameRoundStart !== null)
                                this.state.serverRoundStartTime = new Date(parsedData.gameRoundStart).getTime();

                            if (parsedData.gameStatus === 'EV_ROUND_STARTED') {
                                this.state.roundState = RoundState.ROUND_STARTED;
                            } else {
                                this.state.roundState = RoundState.INIT;
                            }
                            this.updateBetConfig(parsedData);

                            this.fetchMyBetHistory();

                            if (parsedData.currencyType === 'DEMO') {
                                this.state.clientPlayMode = ClientPlayMode.demo;
                            }
                            this.init();
                        })
                        .catch((e) => {
                            this.gRPCFailed = true;
                            //console.log('Fetch player data error:', e);
                        });
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        } else {
            fetchPlayerData(token as string)
                .then((data) => {
                    const parsedData = JSON.parse(data);

                    this.state.balance = parsedData.balance;
                    this.state.name = parsedData.userName;

                    if (parsedData.gameRoundStart !== null)
                        this.state.serverRoundStartTime = new Date(parsedData.gameRoundStart).getTime();

                    if (parsedData.gameStatus === 'EV_ROUND_STARTED') {
                        this.state.roundState = RoundState.ROUND_STARTED;
                    } else {
                        this.state.roundState = RoundState.INIT;
                    }

                    this.updateBetConfig(parsedData);

                    this.fetchMyBetHistory();

                    if (parsedData.currencyType === 'DEMO') {
                        this.state.clientPlayMode = ClientPlayMode.demo;
                    }
                    this.init();
                })
                .catch((e) => {
                    this.gRPCFailed = true;
                    //console.log('Fetch player data error:', e);
                });
        }
    }

    init(){
        this.setPrefix('[[31mApp[39m]');

        const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);

        root.render(createElement(Layout, { app: this }));

        this.initRTMultiplier();
        this.initAudio();
        this.initEvents();
        this.initNetwork();

        this.listenTo(settings, 'animationEnabled', (enabled = settings.proxy.animationEnabled) => {
            this.animationEnabled = enabled;
        })();

        this.bets.add(new Bet(0, this));
        this.bets.add(new Bet(1, this));

        AddPropertiesToWindow({ app: this });
        AddPropertiesToWindow({ config: config });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateBetConfigByKey(key: string, value: any) {
        // @ts-ignore
        this.config.bet[key] = value || this.config.bet[key];
    }

    updateBetConfig({
        bets,
        currency,
        currencyType,
        betStep,
        minBet,
        maxBet,
        maxWin
    }: {
        bets: number[];
        currency: string;
        currencyType: string;
        betStep: number;
        minBet: number;
        maxBet: number;
        maxWin: number;
    }) {
        const precision = currencyType === 'CRYPTO' ? 5 : 3;

        this.updateBetConfigByKey('precision', precision);

        // we need this to adjust data from BE, to cover up the inconsistency
        const currencyMultiplier = Math.pow(10, precision);
        const adjustedFastBets = bets.map((x: number) => x * currencyMultiplier);

        this.updateBetConfigByKey('default', minBet);
        this.updateBetConfigByKey('fastBets', adjustedFastBets);
        this.updateBetConfigByKey('code', currency);
        this.updateBetConfigByKey('step', betStep);
        this.updateBetConfigByKey('min', minBet);
        this.updateBetConfigByKey('max', maxBet);
        this.updateBetConfigByKey('maxWin', maxWin);
        this.updateBetConfigByKey('fractionDigits', 2);

        for (const bet of this.bets) {
            bet.state.value = this.config.bet.default;
        }
    }
    recalculateRTMultiplier() {
        /**
         * We want to slow down so BE can catch up.
         * In time frame of 30ms we want to slow down by 20ms. 20/30 --> 0.66 increase rate
         * What if we need to slow down more than 30ms? 40ms / 30ms --> 1.33 increase rate?? Does not make sense.
         * At that point we have to figure out sth else --> we use faster factor to get into threshold asap.
         * Same goes the other way if we are behind.
         */

        const now = Date.now();
        const timeSinceLastElapsedTime = now - this.roundElapsedTimeUpdate;

        const adjustInterval = 25;
        const outOfSyncAscRate = 5;
        const outOfSyncDescRate = 0.2;

        const isInThreshold = Math.abs(this.remainingTimeDiff) < adjustInterval;
        const adjustmentRate = Math.abs(
            adjustInterval < Math.abs(this.lastTimeDiffOnMultiplier)
                ? adjustInterval / this.lastTimeDiffOnMultiplier
                : this.lastTimeDiffOnMultiplier / adjustInterval
        );

        if (this.state.lastMultiplierUpdate != null && this.state.lastMultiplierUpdate > 0) {
            if (this.remainingTimeDiff < 0) {
                // FE is ahead of the server
                const rate = isInThreshold ? adjustmentRate : outOfSyncDescRate;
                // const rate = outOfSyncDescRate;
                this.roundElapsedTime += timeSinceLastElapsedTime * rate;
                this.remainingTimeDiff = this.remainingTimeDiff + timeSinceLastElapsedTime;
            } else if (this.remainingTimeDiff > 0) {
                // FE is behind the server
                const rate = isInThreshold ? 1 + adjustmentRate : outOfSyncAscRate;
                this.remainingTimeDiff = this.remainingTimeDiff - timeSinceLastElapsedTime * rate;
                this.roundElapsedTime += timeSinceLastElapsedTime * rate;
            } else {
                // FE is synced with the server
                this.roundElapsedTime += timeSinceLastElapsedTime;
            }
        } else {
            //game start
            this.roundElapsedTime = 0; // now - this.roundStartTime;
        }

        this.roundElapsedTimeUpdate = now;
        // values between -1 and 1 can mess this up a bit
        if (Math.abs(this.remainingTimeDiff) < 1) this.remainingTimeDiff = 0;

        const currentElapsedTime = now - this.roundStartTime;

        this.rtMultiplierValue = this.calculator.interpolateMultiplier(new Decimal(currentElapsedTime)).toNumber();
    }

    initRTMultiplier() {
        setInterval(() => {
            if (this.state.roundState === RoundState.ROUND_STARTED) {
                this.recalculateRTMultiplier();
            }
        }, 30);
    }
    get realtimeMultiplier() {
        if (this.state.roundState === RoundState.ROUND_STARTED) {
            return this.rtMultiplierValue;
        } else {
            return this.state.targetMultiplier;
        }
    }
    time2multiplier(time: number) {
        return this.calculator.interpolateMultiplier(new Decimal(time));
    }
    multiplier2time(multiplier: number) {
        return this.calculator.calcTimeForMultiplier(multiplier);
    }

    normalizeTime(time: number) {
        // TODO discuss if needed
        const pingVariability = 0; // this.pingVariability;

        if (isNaN(time)) return 0;

        const rest = new Decimal(time).plus(this.ping).minus(this.timeDiff).plus(pingVariability).toNumber();

        return rest;
    }

    // start ping round trip so we can calculate the ping after receive PingResponse websocket
    startPingRoundTrip() {
        if (!this.network.isSocketOpen()) return;

        const { startTimestamp } = this.network.sendPing() || {};

        if (!startTimestamp) return;

        this.pingArray.push({
            startTimestamp
        });
    }

    addMultipliersHistory(multipliers: THistoryRoundMultipliersEntry[]) {
        for (const multiplier of multipliers.reverse()) {
            this.state.multipliersHistory.unshift(multiplier);
            if (this.state.multipliersHistory.length > 60) this.state.multipliersHistory.pop();
        }
        this.state.multipliersHistory = [...this.state.multipliersHistory];
    }
    addInitialMultiplierHistory(multipliers: THistoryRoundMultipliersEntry[]) {
        this.state.multipliersHistory = [...multipliers];
    }

    initAudio() {
        //this.audio.loadAudio('main');
        //this.audio.audioList.background.play();

        this.listenTo(settings, 'soundsEnabled', (enabled = settings.proxy.soundsEnabled) => {
            this.audio.setSoundVolume(enabled ? 1 : 0);
        })();
        this.listenTo(settings, 'musicEnabled', (enabled = settings.proxy.musicEnabled) => {
            this.audio.setMusicVolume(enabled ? 1 : 0);
        })();
    }
    initEvents() {
        this.state.on('change:roundState', (roundState: RoundState, prevState: RoundState) => {
            switch (roundState) {
                case RoundState.ROUND_STARTED:
                    this.audio.audioList.airplane_start?.play();
                    this.audio.audioList.airplane_start?.volume(this.audio.targetSoundVolume);
                    this.audio.audioList.airplane_start?.on('end', () => {
                        this.audio.audioList.airplane_loop?.play();
                        this.audio.audioList.airplane_loop?.volume(this.audio.targetSoundVolume);
                    });
                    break;
                case RoundState.ROUND_ENDED:
                    this.audio.audioList.airplane_start?.off('end');
                    this.audio.audioList.airplane_start?.stop();
                    this.audio.audioList.airplane_loop?.stop();
                    this.audio.audioList.airplane_end?.play();
                    this.audio.audioList.airplane_end?.volume(this.audio.targetSoundVolume);
                    //this.audio.audioList.airplane_end?.fade(this.audio.targetSoundVolume, 0, 1500);
                    break;
                case RoundState.BET_OPENED:
                    this.currentBetBoard.clear();

                    // seeding
                    // this.onBetCurrentBetEntries([
                    //     {
                    //         id: 1000,
                    //         userId: 52344659,
                    //         img: '',
                    //         bet: 7781,
                    //         currency: 'USD',
                    //         multiplier: 1.88,
                    //         win: 14628
                    //     }
                    // ]);
                    break;
            }
        });
    }
    initNetwork() {
        Ticker.shared.add(() => {
            this.realtimeState.multiplier = this.realtimeMultiplier;
        });

        //WS handler maps correctly @patrik.fiala
        // TODO might delete this later, since it is not used -- just some of the handlers i think
        const handlers: Partial<{ [K in ServerMessageType]: unknown }> = {
            [ServerMessageType.playerState]: (data: IServerPlayerState) => {
                //console.log('player state');
                data.balance && (this.state.balance = data.balance);
                data.mode && (this.state.clientPlayMode = data.mode);
                data.name && (this.state.name = data.name);
            },
            [ServerMessageType.serverState]: (data: IServerState2<RoundStateEvent>) => {
                switch (data.eventName) {
                    case RoundStateEvent.EV_MULTIPLIER: {
                        const now = Date.now();

                        const eventData = data.eventData as RoundState2Message[typeof data.eventName];

                        // target multiplier is the one from server
                        this.state.targetMultiplier = eventData.multiplier;

                        this.state.serverMultiplierElapsedTime = eventData.elapsedTime;
                        this.state.lastMultiplierUpdate = now;

                        const currentTimeDiff = eventData.elapsedTime + this.ping - this.roundElapsedTime;

                        // TODO check normalizeTime
                        this.pingVariability = currentTimeDiff;

                        // these are needed for the multiplier calculation
                        // and its speed (slow down or speed up)
                        this.remainingTimeDiff = currentTimeDiff;
                        this.lastTimeDiffOnMultiplier = currentTimeDiff;

                        TIME_DIFF_HISTOGRAM_ENABLED &&
                            this.timeDiffHistogram.push([moment().format('HH:mm:ss:SSS'), currentTimeDiff]);

                        break;
                    }
                    case RoundStateEvent.EV_BET_OPENED: {
                        const d = data.eventData as RoundState2Message[typeof data.eventName];

                        // this.realtimeState.multiplier = 1; // TODO make DEFAULT_MULTIPLIER
                        //console.log(`[${moment().format('HH:mm:ss')}] - EV_BET_OPENED`, d);

                        this.state.betOpenTime = d.betOpenTime;
                        this.state.betEndTime = d.betEndTime;
                        this.state.serverState = d.serverState;
                        this.state.roundState = d.roundState;

                        this.state.serverRoundEndTime = 0;
                        this.state.serverRoundStartTime = 0;

                        // this helps to sync with server
                        this.startPingRoundTrip();
                        this.startPingRoundTrip();
                        this.startPingRoundTrip();

                        break;
                    }
                    case RoundStateEvent.EV_BET_CLOSED: {
                        const d = data.eventData as RoundState2Message[typeof data.eventName];

                        //console.log(`[${moment().format('HH:mm:ss')}] - EV_BET_CLOSED`, d);

                        this.state.serverState = d.serverState;
                        this.state.roundState = d.roundState;

                        break;
                    }
                    case RoundStateEvent.EV_ROUND_STARTED: {
                        const now = Date.now();
                        const d = data.eventData as RoundState2Message[typeof data.eventName];

                        //console.log(`[${moment().format('HH:mm:ss')}] - EV_ROUND_STARTED`, d);

                        this.state.serverRoundStartTime = d.roundStartTime;
                        this.state.roundStartTimeLocal = now;
                        this.state.serverState = d.serverState;
                        this.state.roundState = d.roundState;

                        this.state.serverRoundEndTime = 0;

                        this.roundElapsedTime = 0;
                        this.remainingTimeDiff = 0;
                        this.lastTimeDiffOnMultiplier = 0;
                        this.roundElapsedTimeUpdate = now;

                        // recalculate the multiplier to get rid of the last value
                        this.recalculateRTMultiplier();

                        break;
                    }
                    case RoundStateEvent.EV_ROUND_ENDED: {
                        const d = data.eventData as RoundState2Message[typeof data.eventName];

                        //console.log(`[${moment().format('HH:mm:ss')}] - EV_ROUND_ENDED`, d);

                        this.state.targetMultiplier = d.multiplier;

                        this.state.lastMultiplierUpdate = 0;
                        this.state.serverMultiplierElapsedTime = 0;

                        this.state.serverState = d.serverState;
                        this.state.serverRoundEndTime = d.roundEndTime;
                        this.state.roundState = d.roundState;

                        // these are needed for the multiplier calculation
                        // and its speed (slow down or speed up)
                        // console.table({
                        //     pingVariability: this.pingVariability,
                        //     remainingTimeDiff: this.remainingTimeDiff,
                        //     lastTimeDiffOnMultiplier: this.lastTimeDiffOnMultiplier
                        // });

                        // update multiplier history
                        this.addMultipliersHistory([
                            {
                                id: d.roundId,
                                multiplier: d.multiplier
                            }
                        ]);

                        //console.log('last few messages'); // , this.messageHistogram);
                        // console.table(
                        //     this.messageHistogram.slice(-4).reduce(
                        //         (acc, [time, msg]) => {
                        //             return {
                        //                 ...acc,
                        //                 [time]: msg
                        //             };
                        //         },
                        //         {} as Record<string, string>
                        //     )
                        // );

                        if (TIME_DIFF_HISTOGRAM_ENABLED) {
                            //console.log('time diff histogram', this.timeDiffHistogram);
                            this.timeDiffHistogram = [];
                        }

                        break;
                    }
                }
            },
            [ServerMessageType.config]: (data: IServerConfigMessage) => {
                Object.assign(this.config, data);
                this.emit(AppEvents.updateConfig);
            },
            //IServerBetEvent todo refac @patrik.fiala
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [ServerMessageType.betProceeded]: (msg: any) => {
                // there is one more handler in Bet.ts, that takes care of cash out process
                const item = {
                    id: msg.eventData.betId,
                    userId: msg.eventData.userId,
                    username: msg.eventData.username,
                    img: msg.eventData.avatarId,
                    bet: adjustAmountByCurrencyConfig(msg.eventData.amount, this.config.bet),
                    multiplier: msg.eventData.multiplier,
                    win: msg.eventData.win,
                    currency: this.config.bet.code
                } as IBetBasicEntry;

                this.onBetCurrentBetEntries([item]);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [ServerMessageType.cashOutProceeded]: (msg: any) => {
                //console.log('cashOutProceeded', msg);
                // there is one more handler in Bet.ts, that takes care of cash out process
                const item = {
                    id: msg.eventData.betId,
                    userId: msg.eventData.userId,
                    username: msg.eventData.username,
                    img: msg.eventData.avatarId,
                    bet: adjustAmountByCurrencyConfig(msg.eventData.amount, this.config.bet),
                    multiplier: msg.eventData.multiplier,
                    win: adjustAmountByCurrencyConfig(msg.eventData.win, this.config.bet),
                    currency: this.config.bet.code
                } as IBetBasicEntry;

                this.onBetCurrentBetEntries([item]);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [ServerMessageType.betCancelProceeded]: (msg: any) => {
                this.onBetCurrentEntriesRemove(msg.eventData.betId);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [ServerMessageType.betCancel]: (msg: any) => {
                this.onBetCurrentEntriesRemove(msg.eventData.betId);
                this.removeItemFromMyBets(msg.eventData.betId);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [ServerMessageType.bet]: (msg: any) => {
                this.appendMyBets(msg.eventData);
            }
        };

        this.network = new Network();

        for (const [type, handler] of Object.entries(handlers)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.network.on(type, (args: any) => {
                this.messageHistogram.push([moment().format('HH:mm:ss:SSS'), `${type} - ${args.eventName}`]);

                // @ts-ignore
                handler(args);
            });
        }

        // This should be probably somewhere else, but there seems to be some inconsistency with the event naming
        this.network.on('cashOutEvent', (msg) => {
            const data = msg.eventData;
            const has = this.myBets.has(data.betId);
            const obj = has ? this.myBets.get(data.betId)! : ({} as MyBetType);

            if (has) {
                const currencyMultiplier = Math.pow(10, this.config.bet.precision);
                obj.win = data.win / currencyMultiplier;
                obj.multiplier = data.multiplier;

                this.myBets.set(data.betId, obj);
            } else {
                // might wanna refetch my bets since this can happen after reconnect
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.network.on('PingResponse', (data: any) => {
            const endTimestamp = Date.now();
            const index = this.pingArray.findIndex((ping) => ping.startTimestamp === data.clientTimestamp);

            if (index === -1) return;

            // https://en.wikipedia.org/wiki/Network_Time_Protocol
            const ping = (endTimestamp - data.clientTimestamp) / 2;
            const timeDiff = (data.serverTimestamp - data.clientTimestamp + (data.serverTimestamp - endTimestamp)) / 2;

            this.pingArray[index].serverTimestamp = data.serverTimestamp;
            this.pingArray[index].endTimestamp = endTimestamp;
            this.pingArray[index].ping = ping;
            this.pingArray[index].timeDiff = timeDiff;

            // TODO doublecheck that this works correctly
            if (this.pingArray.length > 15) {
                this.pingArray = this.pingArray
                    .filter((x) => !!x.ping)
                    .sort((a, b) => (a.ping! > b.ping! ? 1 : a.ping === b.ping ? 0 : -1))
                    .slice(2, -2);
            }

            // calc avg ping
            const filteredArray = this.pingArray.filter((x) => !!x.ping && !!x.timeDiff);
            const avgPing = filteredArray.reduce((acc, curr) => acc + curr.ping!, 0) / filteredArray.length;
            const avgTimeDiff = filteredArray.reduce((acc, curr) => acc + curr.timeDiff!, 0) / filteredArray.length;

            this.ping = avgPing;
            this.emit('ping', ping);
            this.timeDiff = avgTimeDiff;
        });

        this.network.connect(this.state.name);
        this.network.on('connected', async () => {
            // to get more precise time sync, we call the ping multiple times
            this.startPingRoundTrip();
            this.startPingRoundTrip();
            this.startPingRoundTrip();
            this.startPingRoundTrip();
            this.startPingRoundTrip();

            setInterval(() => {
                this.startPingRoundTrip();
            }, 1000);

            // TODO remove before production
            const cheatBalance = !Number.isNaN(parseFloat(String(config.query.balance)))
                ? parseFloat(config.query.balance!)
                : 0;
            await this.network
                .sendAuth({
                    currency: config.query.currency || 'USD',
                    mode: ClientPlayMode.demo,
                    sessionId: this.config.query.token!,
                    cheatBalance: cheatBalance
                })
                .catch(Error);
        });

        this.network.on('error', (data) => {
            // TODO t
            let message = t('Something went wrong.');

            if (data?.Status === wsErrorStatus.ERROR_GAMEROUND_ALREADY_STARTED) {
                message = t('Bet was not set. Round already started.');
            }

            if (data?.Status === wsErrorStatus.ERROR_BET_NOT_FOUND) {
                const betId = data.Error.replace('WsCashOutGrpcCommand - Bet ', '').replace(' is not found.', '');

                const bet = Array.from(this.bets).find((bet) => {
                    return bet.previousBetId === betId || bet.betId === betId;
                });

                if (bet) {
                    message = t('You cashed out too late. Round ended.');
                } else {
                    message = t('Bet was not found.');
                }
            }

            this.network.emit('notification', {
                type: 'error',
                message
            });
        });
    }

    onBetCurrentBetEntries(entries: IBetBasicEntry[]) {
        //console.log('onBetCurrentBetEntries', entries);
        for (const event of entries) {
            const isHas = this.currentBetBoard.has(event.id);

            const obj = isHas ? this.currentBetBoard.get(event.id)! : new BetBoardEntry(event.id);

            Object.assign(obj, event);
            this.currentBetBoard.set(event.id, obj);
        }
    }

    onBetCurrentEntriesRemove(betId: string) {
        this.currentBetBoard.has(betId) && this.currentBetBoard.delete(betId);
    }

    updateBalance({ balance, timestamp }: { balance: number; timestamp: number }) {
        if (!timestamp) {
            throw Error('Missing balance update timestamp');
        }

        if (this.state.balanceUpdate < timestamp) {
            this.state.balance = balance;
            this.state.balanceUpdate = timestamp;
        }
    }

    async fetchMyBetHistory() {
        const parseUrl = new URL(window.location.href);
        const token = parseUrl.searchParams.get('token');

        const res = await fetchBetHistory(token!);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        res.items.forEach((item: any, index: number) => {
            this.myBets.set(index.toString(), mapServerBetToIBetInfo(item));
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    appendMyBets(data: any) {
        const has = this.myBets.has(data.betId);
        const obj = has ? this.myBets.get(data.betId)! : ({} as MyBetType);

        const bets = this.bets.values();
        let bet = bets.next().value;

        if (bet.id !== data.index) {
            bet = bets.next().value;
        }

        obj.id = data.betId;
        obj.roundId = data.roundId;
        obj.date = moment(data.timestamp);
        obj.currency = this.config.bet.code; // might be nice to fetch this from BE as well, but for now it is fine
        obj.multiplier = 0;

        const currencyMultiplier = Math.pow(10, this.config.bet.precision);
        obj.amount = bet.state.value / currencyMultiplier;
        obj.bet = obj.amount;

        this.myBets.set(data.betId, obj);
    }
    removeItemFromMyBets(betId: string) {
        this.myBets.has(betId) && this.myBets.delete(betId);
    }
}
