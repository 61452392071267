// Import polyfills for compatibility with older iOS versions and older browsers
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'abortcontroller-polyfill';
import 'whatwg-fetch';

// Sentry configuration
import * as Sentry from '@sentry/react';
import WebFont from 'webfontloader';
import { App } from './App';
import './i18n';
// import './sandbox/gql';

const WebFontConfig = {
    custom: {
        families: ['Roboto-Regular', 'Roboto-Bold'],
        urls: ['/assets/fonts/fonts.css']
    }
};

const enableProfiling = process.env.NODE_ENV === 'development';

// sentry
Sentry.init({
    dsn: 'https://c3809812ae5d1fe81ff081a84891d1e7@o4507884700762112.ingest.de.sentry.io/4507884703318096',
    // dsn:
    // dsn: process.env.SENTRY_DSN,

    maxBreadcrumbs: 50,
    // debug: true,

    // SESSION REPLAYS
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // replaysSessionSampleRate: 0.1, // TODO set to this one when in production?
    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,

    integrations: [
        Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true
        }),
        Sentry.replayCanvasIntegration(), // This may influence performance
        Sentry.browserTracingIntegration(),
        ...(enableProfiling ? [Sentry.browserProfilingIntegration()] : [])
    ]
});

WebFont.load(WebFontConfig);

new App();
