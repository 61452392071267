/* eslint-disable @typescript-eslint/no-var-requires */
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { decode as decodeMsgPack, encode as encodeMsgPack } from '@msgpack/msgpack';
import { Debugger } from '../../Shared/Debugger';
import { deferrify, Eventify, EventifyMixin } from '../../Shared/Eventify';
import { RoundState2Message } from '../../Shared/EventRoundStates';
import {
    ClientMessageType,
    IClientAuthReq,
    IClientMessage,
    IClientRoundInfoReq,
    IServerBetCancelMessage,
    IServerBetMessage,
    IServerBetProceededMessage,
    IServerMessage,
    Message,
    MessageTypes
} from '../../Shared/MessageTypes';
import { RoundStateEvent } from '../../Shared/Types';
import { ClientBuildEnvironment } from './Types';

declare const __ENVIRONMENT__: ClientBuildEnvironment;

const decode = process.env.USE_JSON_SIGNALR === 'true' ? JSON.parse : decodeMsgPack;
const encode = process.env.USE_JSON_SIGNALR === 'true' ? JSON.stringify : encodeMsgPack;

const enum ClientMethodName {
    Bet = 'Bet',
    BetProceeded = 'BetProceeded',
    BetOpened = 'BetOpened',
    BetClose = 'BetClose',
    CashOut = 'CashOut',
    CashOutProceeded = 'CashOutProceeded',
    PingCaller = 'PingCaller',
    GameRound = 'GameRound',
    StartGameRound = 'StartGameRound',
    EndGameRound = 'EndGameRound',
    BetCancel = 'CancelBet',
    BetCancelProceeded = 'CancelBetProceeded',
    Error = 'Error',
    Close = 'Close'
}

const enum ServerMethodName {
    Bet = 'Bet',
    BetCancel = 'CancelBet',
    PingCaller = 'PingCaller',
    CashOut = 'CashOut'
}

export const enum wsErrorStatus {
    ERROR_BET_NOT_FOUND = 'ERROR_BET_NOT_FOUND',
    ERROR_GAMEROUND_ALREADY_STARTED = 'ERROR_GAMEROUND_ALREADY_STARTED'
}

export default class Network extends EventifyMixin(Debugger) {
    // socket!: WebSocket | FakeWebSocket;
    connection!: HubConnection; // Change type to HubConnection
    comebackTimeout = 500;
    responseCallbacks = new Eventify();
    username: string | null;

    constructor() {
        super();
        this.setPrefix('[[36mNetwork[39m]:');
        this.isLogging = !false;
        this.username = null;
    }

    connect(username: string | null) {
        // this.log('Connecting...');
        this.username = username;
        const hubUrl = `${process.env.GAMEROUND_API_URL}/game?username=${username}`;
        const parseUrl = new URL(window.location.href);
        const token = parseUrl.searchParams.get('token');

        if (process.env.USE_JSON_SIGNALR === 'true') {
            this.connection = new HubConnectionBuilder()
                .configureLogging(6)
                .withUrl(hubUrl, { accessTokenFactory: () => token as string })
                .build();
        } else {
            this.connection = new HubConnectionBuilder()
                .configureLogging(6)
                .withUrl(hubUrl, { accessTokenFactory: () => token as string })
                .withHubProtocol(new MessagePackHubProtocol())
                .build();
        }

        this.connection
            .start()
            .then(() => {
                // this.log('Connected');
                this.onopen();
            })
            .catch((error) => {
                // this.log('Connection failed', error);
            });

        this.connection.onreconnected(() => {
            this.onopen();
        });

        this.connection.onreconnecting(() => {
            //console.log('reconnecting');
            this.rejectAllCallbacks();
            this.reconnect();
        });

        this.connection.onclose(() => {
            this.onclose();
        });

        this.connection.on(ClientMethodName.Close, (binary) => {
            this.connection.stop();
        });

        this.connection.on(ClientMethodName.PingCaller, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: 'PingResponse',
                        serverTimestamp: data.ServerTimestamp,
                        clientTimestamp: data.ClientTimestamp
                    }
                })
            } as MessageEvent<string>);
        });

        this.connection.on(ClientMethodName.Error, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);
            //console.log('WS Error:', data);
            this.emit('error', data);
        });

        this.connection.on(ClientMethodName.CashOut, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            //console.log('>> cash out response from server', data);

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        // TODO fix this one
                        type: 'cashOutEvent',
                        eventName: data.EventName,
                        eventData: {
                            betId: data.EventData.BetId,
                            username: data.EventData.Username,
                            win: data.EventData.Win,
                            balance: data.EventData.Balance,
                            timeElapsed: data.EventData.TimeElapsed,
                            multiplier: data.EventData.Multiplier,
                            timestamp: data.EventData.Timestamp
                        }
                    }
                })
            } as MessageEvent<string>);
        });

        // cashOut proceeded - add data to leader board - broadcast to all clients
        this.connection.on(ClientMethodName.CashOutProceeded, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            //console.log('>> cash out proceeded response', data);

            const parsedData = {
                userId: data.EventData.Token,
                betId: data.EventData.BetId,
                username: data.EventData.Username,
                win: data.EventData.CashOut,
                timeElapsed: data.EventData.TimeElapsed,
                multiplier: data.EventData.Multiplier,
                amount: data.EventData.Amount,
                avatarId: data.EventData.AvatarId
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: data.Type,
                        eventName: data.EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });

        this.connection.on(ClientMethodName.StartGameRound, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            const parsedData: RoundState2Message[RoundStateEvent.EV_ROUND_STARTED] = {
                serverState: data.EventData.ServerState,
                roundState: data.EventData.RoundState,
                multiplier: data.EventData.Multiplier,
                roundStartTime: data.EventData.RoundStartTime
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: data.Type,
                        eventName: data.EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });

        this.connection.on(ClientMethodName.GameRound, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            const parsedData: RoundState2Message[RoundStateEvent.EV_MULTIPLIER] = {
                multiplier: data.EventData.Multiplier,
                elapsedTime: data.EventData.ElapsedTime
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: data.Type,
                        eventName: data.EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });

        this.connection.on(ClientMethodName.EndGameRound, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            const parsedData: RoundState2Message[RoundStateEvent.EV_ROUND_ENDED] = {
                roundState: data.EventData.RoundState,
                serverState: data.EventData.ServerState,
                lastProgress: data.EventData.LastProgress,
                multiplier: data.EventData.Multiplier,
                roundEndTime: data.EventData.RoundEndTime,
                roundId: data.EventData.RoundId,
                elapsedTime: data.EventData.RoundTime
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: data.Type,
                        eventName: data.EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });

        this.connection.on(ClientMethodName.BetOpened, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            const parsedData: RoundState2Message[RoundStateEvent.EV_BET_OPENED] = {
                serverState: data.EventData.ServerState,
                roundState: data.EventData.RoundState,
                multiplier: data.EventData.Multiplier,
                betTimeout: data.EventData.BetTimeout,
                betOpenTime: data.EventData.BetOpenTime,
                betEndTime: data.EventData.BetEndTime
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        // TODO double check this one
                        type: 'serverState',
                        eventName: data.EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });

        //bet - personal bet - only for the user
        this.connection.on(ClientMethodName.Bet, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            //console.log('>> bet response', data);

            const parsedData: IServerBetMessage['eventData'] = {
                balance: data.EventData.Balance,
                betId: data.EventData.BetId,
                roundId: data.EventData.GameRound,
                index: data.EventData.Index,
                timestamp: data.EventData.Timestamp
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: data.Type,
                        eventName: data.EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });

        // bet proceeded - add data to leader board - broadcast to all clients
        this.connection.on(ClientMethodName.BetProceeded, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const data: any = decode(binary);

            //console.log('>> bet proceeded response', data);

            const parsedData: IServerBetProceededMessage['eventData'] = {
                username: data.EventData.Username,
                userId: data.EventData.Token,
                amount: data.EventData.Amount,
                betId: data.EventData.BetId,
                multiplier: 0,
                win: 0,
                avatarId: data.EventData.AvatarId
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: data.Type,
                        eventName: data.EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });

        this.connection.on(ClientMethodName.BetCancel, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { EventData, Type, EventName }: any = decode(binary);

            //console.log('>> bet cancel response', decode(binary));

            const parsedData: IServerBetCancelMessage['eventData'] = {
                balance: EventData.Balance,
                roundId: EventData.RoundId,
                index: EventData.Index,
                betId: EventData.BetId,
                token: EventData.Token,
                timestamp: EventData.Timestamp
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: Type,
                        eventName: EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });

        this.connection.on(ClientMethodName.BetCancelProceeded, (binary) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { EventData, Type, EventName }: any = decode(binary);

            //console.log('>> bet cancel proceeded response', decode(binary));

            const parsedData = {
                betId: EventData.BetId
            };

            this.onmessage({
                data: JSON.stringify({
                    m: MessageTypes.event,
                    data: {
                        type: Type,
                        eventName: EventName,
                        eventData: parsedData
                    }
                })
            } as MessageEvent<string>);
        });
    }
    close() {
        // if (this.socket) {
        //     this.socket.onopen = null;
        //     this.socket.onmessage = null;
        //     this.socket.onerror = null;
        //     this.socket.onclose = null;
        //     if (this.isSocketOpen()) {
        //         this.log('Disconnected');
        //         this.socket.close();
        //         this.rejectAllCallbacks();
        //     }
        // }
        // this.socket = undefined as unknown as WebSocket;
        if (this.connection) {
            this.connection.stop();
        }
    }
    isSocketOpen() {
        return this.connection.state === 'Connected';
    }

    increaseComeback() {
        this.comebackTimeout = this.comebackTimeout < 40000 ? this.comebackTimeout * 2 : 40000;
    }

    reconnect() {
        // this.log(`Reconnecting in ${this.comebackTimeout}ms`);
        setTimeout(() => {
            this.connect(this.username);
        }, this.comebackTimeout);
        this.increaseComeback();
    }

    private onopen() {
        // this.log('Connected');
        this.emit('connected');
        this.comebackTimeout = 500;
    }

    private onmessage(data: MessageEvent<string>) {
        const message = JSON.parse(data.data);

        if (message.data) {
            this.emit(message.data.type, message.data);
        }

        if (message.callback)
            this.responseCallbacks.emit(message.callback, message.data, message.errorMessage !== undefined);

        if (message.errorMessage) {
            this.emit('notification', {
                type: 'error',
                message: message.errorMessage
            });
        }
    }

    private onerror() {}

    private onclose() {
        // this.log('Disconnected');
        this.emit('disconnected');
        this.rejectAllCallbacks();
    }

    private rejectAllCallbacks() {
        for (const callbackId in this.responseCallbacks.events) {
            this.responseCallbacks.emit(callbackId, null, true);
        }
        this.emit('notification', {
            type: 'error',
            message: 'Server connection closed'
        });
    }

    //never mind
    send(message: IClientMessage, signal?: AbortSignal) {
        //Send socket - do not use (we will use rest api)
        // todo сделать запвисмость от состояния сокета. если он открывается, то поставить промис
        // if (!(this.socket && this.isSocketOpen())) return Promise.reject();
        if (!this.isSocketOpen()) return Promise.reject();
        const dpromise = deferrify<IServerMessage>(signal ? { signal } : undefined);

        const id = Math.random().toString(36).substring(2, 9);

        const body: Message = {
            m: MessageTypes.rpc,
            callback: id,
            data: message
        };

        const listener = (response: IServerMessage, isRejected: boolean) => {
            this.responseCallbacks.removeListener(id, listener);

            if (isRejected) {
                dpromise.reject(response);
            } else {
                dpromise.resolve(response);
            }
        };
        this.responseCallbacks.on(id, listener);

        // this.log('out', body);
        this.connection.send(JSON.stringify(body));

        return dpromise.promise;
    }

    sendPing() {
        try {
            const startTimestamp = Date.now();
            const binary = encode({ ClientTimestamp: startTimestamp });

            const invokePromise = this.connection.invoke(ServerMethodName.PingCaller, binary);

            return { startTimestamp, invokePromise };
        } catch (e) {
            //console.log('error', e);
        }
    }

    sendAuth(data: Omit<IClientAuthReq, 'type'>) {
        const auth: IClientAuthReq = {
            type: ClientMessageType.auth,
            ...data
        };
        return this.send(auth);
    }

    sendCashOut(betId: string) {
        try {
            const data = { BetId: betId };
            //console.log('>> cash out request', data);

            const binary = encode(data);
            return this.connection.invoke(ServerMethodName.CashOut, binary);
        } catch (e) {
            //console.log('error', e);
        }
    }

    sendSetBet({
        amount,
        autoCashOutMultiplier,
        id,
        avatarId
    }: {
        amount: number;
        autoCashOutMultiplier?: number | null;
        id: number;
        avatarId: number | string;
    }) {
        try {
            const data = {
                Amount: amount,
                Index: id, // we need to be able to recognize which button/bet was sent
                WsConnectionId: this.connection.connectionId,
                AvatarId: avatarId,
                ...(autoCashOutMultiplier ? { AutoCashout: autoCashOutMultiplier } : {})
            };

            //console.log('>> bet request', data);

            const binary = encode(data);
            return this.connection.invoke(ServerMethodName.Bet, binary);
        } catch (e) {
            //console.log('error', e);
        }
    }

    sendCancelBet(betId: string) {
        try {
            const data = { BetId: betId, Currency: 'XXX' };
            //console.log('>> bet cancel', data);

            const binary = encode(data);
            return this.connection.invoke(ServerMethodName.BetCancel, binary);
        } catch (e) {
            //console.log('error', e);
        }
    }

    //todo rest api
    getRoundInfo(roundId: string) {
        const data: IClientRoundInfoReq = {
            type: ClientMessageType.roundInfo,
            roundId: roundId
        };

        return {
            id: 'test-id',
            betOpen: 10,
            betClose: 100,
            roundStart: 1000,
            roundEnd: 1000,
            multiplier: 1.3244,
            serverSeed: 'server-seed-test',
            participants: [],
            bets: [],
            hash: 'hash-test',
            hex: 'hex-string-test',
            decimal: 1.0
        };
    }
}

//todo refactor
export interface CashoutResponse {
    token?: string;
    balance?: number;
    transactionId?: string;
}
