import Decimal from 'decimal.js-light';
import { t } from 'i18next';
import { Eventify, EventObject } from '../../Shared/Eventify';
import {
    IServerBetMessage,
    IServerCashOutMessage,
    IServerConfigMessage,
    ServerMessageType
} from '../../Shared/MessageTypes';
import { RoundState } from '../../Shared/Types';
import { App } from './App';
import { config } from './config';
import { money } from './utils/utils';

export interface IAutoplayParams extends Record<string, unknown> {
    rounds: number;
    stopIfCashDecreases: boolean;
    stopIfCashDecreasesBy: number;
    stopIfCashIncreases: boolean;
    stopIfCashIncreasesBy: number;
    stopIfSingleWinExceeds: boolean;
    stopIfSingleWinExceedsValue: number;
}

export default class Bet extends Eventify {
    betId: string = '';
    previousBetId: string = '';
    state = EventObject({
        visible: true,
        autoPlayVisible: false,

        value: 0,

        cashedOut: false,
        winMultiplier: 0,
        winValue: 0,

        setted: false, // bet is set
        pending: false,

        waitBetOpen: false, // waiting for next round to bet

        autoCashoutEnabled: false,
        autoCashOutValue: config.autoplay.minAutoCashOutMultiplier
    });

    ui = EventObject({
        cashOutVisible: false,
        cancelVisible: false,
        buttonsEnabled: !false
    });

    _autoplay = {
        rounds: 0,
        // relative balance decrease
        stopIfCashDecreases: false,
        stopIfCashDecreasesBy: 0,
        // relative balance increase
        stopIfCashIncreases: false,
        stopIfCashIncreasesBy: 0,
        // single win
        stopIfSingleWinExceeds: false,
        stopIfSingleWinExceedsValue: 0,
        // game data
        roundsLeft: 0,
        relativeBalance: 0
    };

    autoplay = EventObject(this._autoplay);

    get isAutoplay() {
        return this.autoplay.roundsLeft > 0;
    }

    constructor(
        public id: number,
        public app: App
    ) {
        super();

        // set default bet values
        this.state.value = this.app.config.bet.default;

        if (process.env.NODE_ENV === 'development') {
            if (this.id === 0) {
                this._autoplay.rounds = 100;
                // this._autoplay.stopIfCashDecreases = true;
                // this._autoplay.stopIfCashDecreasesBy = 100000;
                this._autoplay.stopIfCashIncreases = true;
                this._autoplay.stopIfCashIncreasesBy = 5000;

                this.state.autoCashoutEnabled = true;
                this.state.autoCashOutValue = 1.5;
            }

            if (this.id === 1) {
                this._autoplay.rounds = 10;
                this._autoplay.stopIfCashDecreases = true;
                this._autoplay.stopIfCashDecreasesBy = 100000;

                this.state.autoCashoutEnabled = true;
                this.state.autoCashOutValue = 2.5;
            }
        }

        this.listenTo(this.app.network, ServerMessageType.config, (data: IServerConfigMessage) => {
            this.state.value = data.bet.default;
        });

        this.listenTo(this.app.network, ServerMessageType.cashOutEvent, (event: IServerCashOutMessage) => {
            //console.log('cashOutEvent');
            if (event.eventData.betId === this.betId && event.eventData!.win > 0) {
                this.onWin(event);
            }
        });

        this.listenTo(app.state, 'roundState', () => {
            if (app.state.roundState === RoundState.BET_OPENED) {
                this.state.cashedOut = false;
                this.resetWinState();

                if (this.state.waitBetOpen) {
                    //console.log('?? round state change and waiting for bet open');

                    if (this.isAutoplay) {
                        //console.log('?? try to set bet autoplay');
                        this.tryAutoplay();
                    } else {
                        //console.log('?? set bet');
                        this.setBet();
                    }
                }
            }

            if (app.state.roundState === RoundState.ROUND_ENDED && !this.state.pending) {
                this.roundEndEvents();
            }

            if (app.state.roundState === RoundState.BET_CLOSED) {
                //console.log('disabled button');
                this.state.pending = true;
            }

            if (app.state.roundState === RoundState.ROUND_STARTED) {
                //console.log('enabled button');
                this.state.pending = false;
            }
        })();

        this.listenTo(this.app.network, ServerMessageType.bet, (event: IServerBetMessage) => {
            if (event.eventData.index === this.id) {
                this.betId = event.eventData.betId;
                this.state.setted = true;
                this.state.pending = false;

                this.app.updateBalance(event.eventData);
            }
        });

        // wait for bet to be proceeded -- saved in DB
        this.listenTo(this.app.network, ServerMessageType.betProceeded, (event: IServerBetMessage) => {
            if (event.eventData.betId === this.betId) {
                this.state.pending = false;
            }
        });

        this.listenTo(this.app.network, ServerMessageType.betCancel, (event: IServerBetMessage) => {
            //console.log('betCancel', event.eventData.betId, this.betId);
            //console.log('betCancel', event.eventData, this.id);

            if (event.eventData.betId === this.betId) {
                this.betId = '';
                this.state.setted = false;
                this.state.pending = false;

                this.app.updateBalance(event.eventData);
            }
        });

        this.listenTo(this.app.network, ServerMessageType.cashOut, (event: IServerCashOutMessage) => {
            if (event.eventData.betId === this.betId && event.eventData!.win > 0) {
                this.onWin(event);
            }
        });

        this.listenTo(app.state, 'roundState', this.updateUi.bind(this));
        this.listenTo(this.state, 'change:*', this.updateUi.bind(this));
        this.listenTo(app.state, 'roundState', () => {
            if (app.state.roundState === RoundState.BET_OPENED) {
                if (this.isAutoplay && !this.state.setted) this.tryAutoplay();
            }
            if (app.state.roundState === RoundState.ROUND_ENDED) {
                if (this.isAutoplay) this.state.waitBetOpen = true;
            }
        });
    }

    roundEndEvents() {
        this.previousBetId = this.betId;
        this.betId = '';
        this.state.setted = false;
        this.state.pending = false;
    }
    getCashoutValue(): number {
        const multiplier = this.state.cashedOut ? this.state.winMultiplier : this.app.realtimeState.multiplier;

        if (this.state.cashedOut) {
            return this.state.winValue;
        } else {
            // TODO might need to use the FLOOR here
            // return new Decimal(this.state.value)
            // .times(new Decimal(multiplier).toDecimalPlaces(2, Decimal.ROUND_FLOOR))
            // .toDecimalPlaces(0, Decimal.ROUND_FLOOR)
            // .toNumber();
            return new Decimal(this.state.value).times(multiplier).toDecimalPlaces(0, Decimal.ROUND_FLOOR).toNumber();
        }
    }
    getCashOutValueFormatted(): string {
        return money(this.getCashoutValue(), this.app.config.bet);
    }
    updateUi() {
        this.ui.cashOutVisible = this.state.setted && this.app.state.roundState == RoundState.ROUND_STARTED;

        this.ui.cancelVisible =
            (this.state.setted && this.app.state.roundState == RoundState.BET_OPENED) || this.state.waitBetOpen;
        this.ui.buttonsEnabled = !(this.ui.cancelVisible === true || this.ui.cashOutVisible === true);
    }
    // TODO is this needed? delete?
    waitResp = <T extends Promise<unknown>>(promise: T) => {
        this.state.pending = true;
        const p = promise.catch(Promise.reject).finally(() => (this.state.pending = false));

        return p;
    };
    destroy() {
        this.state.unlisten();
        this.unlisten();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onWin = (data: IServerCashOutMessage) => {
        //console.log('onWin', data);

        this.state.pending = false;
        this.state.setted = false;
        this.state.cashedOut = true;

        if (this.isAutoplay) {
            //console.log('onWin autoplay', data, this.autoplay.relativeBalance);
            const winAmount = data.eventData.win;

            this.autoplay.relativeBalance += winAmount;

            // should stop?
            if (
                (this._autoplay.stopIfSingleWinExceeds && winAmount >= this._autoplay.stopIfSingleWinExceedsValue) ||
                this.shouldStopAutoplay()
            ) {
                //console.log('?? top autoplay');

                this.stopAutoPlay();
            } else {
                //console.log('?? onwin try autoplay');
                this.tryAutoplay();
            }
        }

        this.previousBetId = this.betId;
        this.betId = '';

        this.state.winMultiplier = data.eventData.multiplier;
        this.state.winValue = Number(data.eventData.win.toFixed(0));

        this.app.updateBalance(data.eventData);

        // TODO
        // this.app.audio.audioList.info.play();

        this.app.network.emit('notification', {
            type: 'cashout',
            cashoutData: {
                win: Number(data.eventData.win.toFixed(0)),
                multiplier: data.eventData.multiplier
            }
        });

        this.updateUi();
    };

    shouldStopAutoplay() {
        const hasEnoughRounds = this.autoplay.roundsLeft > 0;

        if (!hasEnoughRounds) {
            //console.log('?? stop autoplay because of rounds');
            return true;
        }

        const isDecreased =
            this.autoplay.relativeBalance <= -this.autoplay.stopIfCashDecreasesBy && this.autoplay.stopIfCashDecreases;

        const isIncreased =
            this.autoplay.relativeBalance >= this.autoplay.stopIfCashIncreasesBy && this.autoplay.stopIfCashIncreases;

        //console.log('isDecreased', isDecreased, 'isIncreased', isIncreased, this.autoplay.relativeBalance);

        return isDecreased || isIncreased;
    }

    tryAutoplay = async () => {
        //console.log('?? try autoplay');

        // if (this.state.setted || this.state.pending) return;

        if (this.shouldStopAutoplay()) {
            //console.log('?? stop the autoplay');
            this.stopAutoPlay();
            return;
        }

        const res = await this.setBet();

        //console.log('?? try autoplay res', res);

        if (res) {
            //console.log('?? is autoplay', this.autoplay.relativeBalance, this.state.value);

            this.autoplay.relativeBalance -= this.state.value;
            this.autoplay.roundsLeft--;
        }
    };

    async setBet(): Promise<boolean> {
        if (this.state.pending) {
            //console.log('?? bet is pending');
            return false;
        }

        if (this.state.value > this.app.state.balance) {
            this.app.network.emit('notification', {
                type: 'error',
                message: t('Not enough money')
            });

            return false;
        }

        let result = true;

        if (this.app.state.roundState === RoundState.BET_OPENED) {
            this.state.cashedOut = false;
            this.state.waitBetOpen = false;

            // optimistically set bet, so there is no weird visual change
            this.state.setted = true;
            this.state.pending = true;

            await this.app.network
                .sendSetBet({
                    amount: this.state.value,
                    autoCashOutMultiplier: this.state.autoCashoutEnabled ? this.state.autoCashOutValue : null,
                    id: this.id,
                    avatarId: Number(this.app.avatarId)
                })
                ?.catch((e) => {
                    // TODO delete later
                    console.error('!!!!!!!!! Error:', e);

                    if (!this.isAutoplay) {
                        this.state.setted = false;
                        this.state.pending = false;
                    } else {
                        //console.log('reset autoplay values');
                        this.state.pending = false;
                        this.state.setted = false;
                        this.state.waitBetOpen = true;
                    }

                    result = false;
                });

            return result;
        } else {
            //console.log('?? bet not opened');
            this.state.waitBetOpen = true;

            return false;
        }
    }

    cancelBet = () => {
        // so we cannot click the button multiple times
        if (this.state.pending) return;

        if (this.betId !== '' && !this.state.pending) {
            this.state.pending = true;
            // this happens only during BET_OPENED state
            this.app.network.sendCancelBet(this.betId)?.catch((e) => {
                // TODO delete later
                //console.log('Error:', e);
                this.state.setted = false;
                this.state.pending = false;
            });
        }

        this.state.waitBetOpen = false;

        if (this.isAutoplay) this.stopAutoPlay();

        this.state.setted = false;
    };

    cashOut = async () => {
        try {
            this.state.pending = true;

            //console.log('cashout timediff', this.app.remainingTimeDiff);

            await this.app.network.sendCashOut(this.betId)?.catch(() => {
                // cashout fails because it did not arrive on BE before round ends
                // --> it cannot find the betId in the new round
                // we set this bet as not setted and handle it in WS error handler
                // console.log('cashout error catch', this.betId);
                this.roundEndEvents();

                if (this.isAutoplay) {
                    this.state.waitBetOpen = true;
                }

                //console.log('cashout error catch', this.betId);
            });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    async startAutoPlay(rounds = this.autoplay.rounds) {
        this.autoplay.roundsLeft = rounds;
        await this.tryAutoplay();
    }
    resetAutoPlay() {
        Object.assign(this.autoplay, {
            rounds: 0,
            // relative balance decrease
            stopIfCashDecreases: false,
            stopIfCashDecreasesBy: 0,
            // relative balance increase
            stopIfCashIncreases: false,
            stopIfCashIncreasesBy: 0,
            // single win
            stopIfSingleWinExceeds: false,
            stopIfSingleWinExceedsValue: 0,
            // game data
            roundsLeft: 0,
            relativeBalance: 0
        });
    }
    resetWinState() {
        this.state.cashedOut = false;
        this.state.winMultiplier = 0;
        this.state.winValue = 0;
    }

    async cancelAutoPlay() {
        if (this.state.pending) return;

        // round has started -- we have to cashout at this point
        if (this.app.state.roundState === RoundState.ROUND_STARTED && this.state.setted) {
            this.stopAutoPlay();
            await this.cashOut();
        } else {
            this.cancelBet();
        }
    }
    stopAutoPlay() {
        this.autoplay.roundsLeft = 0;
        this.autoplay.relativeBalance = 0;

        this.state.setted = false;
        this.state.pending = false;
        this.state.waitBetOpen = false;
    }
}
